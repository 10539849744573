import React, { useState } from 'react';
import './ContactSection.css';
import axios from 'axios';

interface ContactSectionProps {
  id: string;
}

const API_URL = process.env.REACT_APP_API_URL;

const ContactSection: React.FC<ContactSectionProps> = ({ id }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errors, setErrors] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
  });

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePhone = (phone: string) => {
    const phonePattern = /^[0-9]{10}$/;
    return phonePattern.test(phone);
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let newErrors = { fullName: '', email: '', phone: '', message: '' };

    if (!formData.fullName) newErrors.fullName = 'Full Name is required';
    if (!validateEmail(formData.email))
      newErrors.email = 'Invalid Email Address';
    if (!validatePhone(formData.phone))
      newErrors.phone = 'Please enter a valid 10-digit phone number.';
    if (!formData.message) newErrors.message = 'Message is required';

    setErrors(newErrors);

    if (
      !newErrors.fullName &&
      !newErrors.email &&
      !newErrors.phone &&
      !newErrors.message
    ) {
      try {
        const payload = {
          ...formData,
          businessEmail: 'inquiries@nexstepweb.com'
        };

        await axios.post(`${API_URL}/api/contact`, payload);
        setSuccessMessage(
          'Thank you for reaching out! We have received your inquiry and will get in touch soon.'
        );
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          message: '',
        });
      } catch (error) {
        console.error('Error sending the message:', error);
        setSuccessMessage(
          'There was an error sending your message. Please try again later.'
        );
      }
    }
  };

  return (
    <section className="contact-section" id={id}>
      <h2 className="contact-title">Contact Me</h2>
      <p className="contact-description">
        I’d love to hear about your project needs. Reach out and let’s start
        building your website!
      </p>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            placeholder="e.g., John Doe"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
          {errors.fullName && <p className="error-text">{errors.fullName}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="e.g., johndoe@example.com"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p className="error-text">{errors.email}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="e.g., 1234567890"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          {errors.phone && <p className="error-text">{errors.phone}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="Tell me about your project needs..."
            rows={4}
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          {errors.message && <p className="error-text">{errors.message}</p>}
        </div>

        <button type="submit" className="submit-btn">
          Send
        </button>
      </form>
      {successMessage && (
        <div className="success-message">
          <button className="close-btn" onClick={() => setSuccessMessage('')}>
            ×
          </button>
          {successMessage}
        </div>
      )}
    </section>
  );
};

export default ContactSection;
