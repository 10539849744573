import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();
  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    topRef?.current?.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }, [pathname]);

  return <div ref={topRef}></div>; // Empty div to serve as scroll target
};

export default ScrollToTop;
