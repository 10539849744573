import React from 'react';

const TemplatePage: React.FC = () => {
  return (
    <main>
      <header>

      </header>
    </main>
  );
};

export default TemplatePage;
