import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import PortfolioPage from './pages/PortfolioPage';
import SocialMediaSection from './components/SocialMediaSection';
import './App.css'; 
import ScrollToTop from './components/ScrollToTop';
import TemplatePage from './pages/TemplatePage';

const App: React.FC = () => {
  return (
    <div>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/templates" element={<TemplatePage />} />
      </Routes>
      <SocialMediaSection id='socialMediaSection'/>
      <Footer year={new Date().getFullYear()} />
    </div>
  );
};

export default App;
